<div class="container-fluid login-container">
  <div class="row">
    <!-- Left Side Image -->
    <div class="col-md-8 p-4">
      <img src="assets/img/login.jpg" alt="Login Image" class="img-fluid" style="height: 90vh;">
    </div>

    <!-- Right Side Login Form -->
    <div class="col-md-3">
      <div class="login-form">
        <h2 class="mb-4">Login</h2>
        <form class="mt-5">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta similique sit quos doloremque, deserunt voluptates, omnis consequatur exercitationem aut officiis earum, repellat laudantium deleniti quae repudiandae error sed reiciendis amet!</p>
          <button type="button" (click)="login()" class="btn btn-outline-primary w-100 mt-5">
            <i class="bi bi-microsoft"></i> Sign in with Microsoft
           </button>
        </form>
      </div>
    </div>
  </div>
</div>
