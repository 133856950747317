<div class="flex-shrink-0 p-3" style="width: 280px; height:100%;">
  <a routerLink="/settings/mmssetting"
    class="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none justify-content-center">
    <img class="bi pe-none me-2" width="60%" height="45" src="assets/img/logo.png" />
  </a>
  <div class="side-bar-container">
    <ul class="list-unstyled ps-0 sidebar">
      <li [class.active]="router.url === '/settings/mmssetting'"><a routerLink="/settings/mmssetting"
          class="link-body-emphasis d-inline-flex text-decoration-none rounded w-95">Shared Configurations</a></li>
      <li [class.active]="router.url === '/settings/statesetting'"><a routerLink="/settings/statesetting"
          class="link-body-emphasis d-inline-flex text-decoration-none rounded w-95">State Configurations</a></li>
      <li [class.active]="router.url === '/settings/pharmacysetting'"><a routerLink="/settings/pharmacysetting"
          class="link-body-emphasis d-inline-flex text-decoration-none rounded w-95">Pharmacy Configurations</a></li>
    </ul>
  </div>
</div>