import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BreadcrumbModule,
    FormsModule,
    AuthModule.forRoot({
      domain: environment.domain,//'devprimerx.us.auth0.com',
      clientId: environment.clientId,// 'NxWqozdIybdEyVb3Zu8EqvkqNcBi60m2',      
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience:environment.audience
      }
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
    
 }
