import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular'
import { AuthService as Auth } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private auth: Auth, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //if (this.authService.isAuthenticated)) {
    //   return true;
    // } else {
    //   // Redirect to the login page if not logged in
    //   return this.router.createUrlTree(['/login']);
    // }
    return this.authService.isAuthenticated$.pipe(map(isAuthenticated => {
      if (!isAuthenticated) {
        this.auth.isLoggedInValue = false;
        return this.router.createUrlTree(['/login']);
      }
      else {
        this.auth.isLoggedInValue = true;
        return true;
      }
    }));
  }
}
