import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { StorageServiceService } from './services/storage-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'routing-demo';
  constructor(public authService: AuthService, private localstorageService: StorageServiceService) {

  }
  ngOnInit(): void {
  }
}
