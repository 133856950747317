import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Token } from '@angular/compiler';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth.accesstoken.pipe(
      switchMap(token => {
        if (token) {
          // Clone the request and attach the token
          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'
            }
          });
          console.log(authReq);
          return next.handle(authReq);
        }

        // If there is no token, pass the original request
        return next.handle(req);
      }
      )
    )
  }
}
