import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {

  constructor() { }
  AddSubmissionDate(submisstonDate: Date) {
    localStorage.setItem("submisstionDate", submisstonDate.toString())
  }
  GetSubmissionDate(): Date {
    const dateStringFromStorage = localStorage.getItem('submisstionDate');
    // Check if the date string exists in local storage
    if (dateStringFromStorage) {
      // Parse the date string into a Date object
      return new Date(dateStringFromStorage);
    } else {
      return new Date();
    }
  }
}
